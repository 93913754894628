* {
  box-sizing: border-box;
}

html, body {
  --font-size-h6: 12px;
  --font-size: 14px;
  --font-size-h4: 16px;
  --font-size-h3: 20px;
  --font-size-h2: 24px;
  --font-size-h1: 28px;
  --font-size-heading: 20px;
  --font-size-subheading: 12px;
  --font-family: 'halyard-display', sans-serif;
  --font-family-subheading: 'Work Sans', sans-serif;
  --color-bw-0: #fff;
  --color-bw-1: #F8F9FA;
  --color-bw-2: #E1E7EC;
  --color-bw-3: #D5DDE5;
  --color-bw-4: #CCD4DB;
  --color-bw-5: #AEBECD;
  --color-bw-6: #929FB1;
  --color-bw-7: #6E7A8A;
  --color-bw-8: #404B5A;
  --color-bw-9: #202833;
  --color-primary: #2186EB;
  --color-secondary: #F7C948;
  --color-text: var(--color-bw-1000);
  --border-radius: 4px;
  --letter-spacing-subheading: 1px;
  --overlay-fade: linear-gradient(transparent, rgba(0,0,0,0.9));
  --text-shadow-default: 0 1px 1px var(--color-black-1);

  color: var(--color-text);
  font-size: var(--font-size);
  font-family: var(--font-family);
  background: var(--color-bw-1);
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size);
}

h6 {
  font-size: var(--font-size-h6);
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

input {
  font-family: var(--font-family);
}

.ui-button {
  display: inline-block;
  height: 29px;
  padding: 0 8px;
  color: var(--color-bw-0);
  background: var(--color-bw-9);
  border: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  border-radius: var(--border-radius);
  outline: 0;
  cursor: pointer;
}

.ui-button.is-primary {
  background: var(--color-primary);
}

.ui-input {
  display: flex;
  align-items: center;
  padding: 8px 0;
  width: 50%;
  color: var(--color-bw-4);
  background: transparent;
  /* border: 1px solid var(--color-bw-3); */
  border: 0;
  border-bottom: 1px dashed var(--color-bw-3);
  border-radius: var(--border-radius);
  outline: 0;
}

.editor-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 360px;
  background: var(--color-bw-9);
}

.editor-sidebar > .menu {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: var(--color-bw-8);
}

.editor-sidebar > .menu > .title {
  color: var(--color-bw-0);
}

.editor-sidebar > .list {
  padding: 24px;
  height: 100%;
}

.editor-sidebar > .list > .heading {
  color: var(--color-bw-2);
  margin-bottom: 16px;
  font-weight: 400;
}

.editor-sidebar > .footer {
  display: flex;
  align-items: center;
  color: var(--color-bw-5);
  border-top: 1px solid var(--color-bw-8);
  margin-left: 24px;
  margin-right: 24px;
  padding: 24px 0;
}

.editor-sidebar > .footer > .social {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.editor-sidebar > .footer > .social > .icon {
  text-decoration: none;
  color: var(--color-bw-5);
  font-size: 16px;
}

.editor-sidebar > .footer > .social > .icon:not(:last-child) {
  margin-right: 16px;
}

.editor-widget {
  padding: 16px;
  user-select: none;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  background: var(--color-bw-8);
  border-radius: var(--border-radius);
}

/**
 * Placeholder for the widget being dropped
 */
.editor-widget.is-cloned {
  background: transparent;
  border: 1px dashed var(--color-bw-8);
}

/**
 * Workaround so the widgets below don't "flickr" while we're dragging.
 * Thanks, pixelass (GitHub).
 * https://github.com/atlassian/react-beautiful-dnd/issues/216#issuecomment-475837094
 */
.editor-widget.is-cloned ~ div {
  transform: none !important;
}

.editor-widget > .icon {
  color: var(--color-bw-5);
  margin-right: 12px;
}

.editor-widget > .label {
  color: var(--color-bw-0);
  font-size: var(--font-size-h4);
  font-weight: 300;
}

/**
 * rbd doesn't support dragging from fixed elements yet.
 * This is the suggested workaround by jasonlewicki.
 * @source https://github.com/atlassian/react-beautiful-dnd/issues/1275
 */
.editor-layout {
  height: 100vh;
  margin-left: 360px;
  overflow-y: scroll;
}

.editor-cover {
  height: 180px;
  background: var(--color-primary);
}

.editor-cover > .container {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 56px;
  width: 720px;
}

.editor-cover > .container > .text {
  width: 100%;
  color: var(--color-bw-0);
  opacity: 0.75;
  font-weight: 300;
}

.editor-content {
  position: relative;
  width: 720px;
  margin: 0 auto;
  margin-top: -40px;
}

.editor-content > .new {
  text-align: center;
  padding: 64px;
}

.editor-content > .new > .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
  color: var(--color-bw-9);
  background: var(--color-bw-4);
  border: 0;
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
}

.editor-content-list {
  padding-bottom: 60px;
}

.editor-section {
  position: relative;
  margin-bottom: 60px;
  background: var(--color-bw-0);
  border: 1px solid var(--color-bw-3);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  transition: 200ms all ease;
}

.editor-section > .count {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px 24px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-bw-0);
  background: var(--color-primary);
  border-top-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.editor-section > .heading {
  display: flex;
  align-items: center;
  padding: 24px 0;
  padding-top: 40px;
  margin-left: 24px;
  margin-right: 24px;
  border-bottom: 1px solid var(--color-bw-3);
}

.editor-section > .heading > .title {
  width: 50%;
}

.editor-section > .heading > .menu {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.editor-section > .heading > .menu > .action {
  color: var(--color-bw-6);
}

.editor-section > .heading > .menu > button.action {
  display: inline-block;
  padding: 0;
  font-size: inherit;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.editor-section > .heading > .menu > .action:not(:last-child) {
  margin-right: 24px;
}

.editor-section > .content {  
  height: 100%;
  min-height: 180px;
  padding-top: 24px;
}

.editor-section.is-dragging {
  border: 1px dashed var(--color-primary);
}

.editor-section-widget {
  padding: 24px;
  background: var(--color-bw-0);
  cursor: pointer;
}

.editor-section-widget:last-child {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.editor-section-widget > .label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}

.editor-section-widget > .label > .text {
  font-size: var(--font-size-h6);
  font-weight: 400;
}

.editor-section-widget > .label > .info {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: var(--color-bw-6);
}

.editor-section-widget > .label > .info > .icon {
  margin-right: 8px;
}

.editor-section-widget > .label > .info > .text {
  font-size: var(--font-size-h6);
}

.editor-section-widget > .label > .info > .action {
  margin-left: 16px;
}

.editor-section-widget > .footer {
  display: none;
  padding-top: 16px;
  border-top: 1px solid var(--color-bw-2);
}

.editor-section-widget > .footer > .content {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.editor-section-widget > .input {
  margin-bottom: 32px;
}

.editor-section-widget > .footer > .content > .section:not(:last-child) {
  margin-right: 32px;
}

.editor-section-widget > .footer > .content > .section > .action {
  font-size: var(--font-size);
  color: var(--color-bw-6);
}

.editor-section-widget > .footer > .content > .section > button.action {
  font-size: var(--font-size);
  color: var(--color-bw-6);
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
}

.editor-section-widget > .footer > .content > .section > .group {
  display: flex;
  align-items: center;
}

.editor-section-widget > .footer > .content > .section > .group > .label {
  color: var(--color-bw-6);
  margin-right: 16px;
}

.editor-section-widget.is-dragging,
.editor-section-widget.is-selected {
  cursor: inherit;
  border: 1px dashed var(--color-primary);
}

.editor-section-widget.is-dragging {
  background: #e5f2ff;
}

.editor-section-widget.is-selected > .footer {
  display: flex;
}

.editor-section.is-dragging .editor-section-widget {
  border: 0;
}

.ui-clear-input {
  display: block;
  width: 100%;
  height: var(--font-size);
  font-size: var(--font-size);
  color: inherit;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
}

.ui-clear-input.is-h1 {
  height: var(--font-size-h1);
  font-weight: 300;
  font-size: var(--font-size-h1);
}

.ui-switch-toggle {
  position: relative;
  cursor: pointer;
}

.ui-switch-toggle > .bar {
  width: 32px;
  height: 12px;
  background: var(--color-bw-2);
  border-radius: 6px;
}

.ui-switch-toggle > .control {
  position: absolute;
  top: -2px;
  right: 0;
  height: 16px;
  width: 16px;
  background: var(--color-primary);
  border-radius: 50%;
}

.ui-checkbox-group {
  display: flex;
  width: 50%;
}

.ui-checkbox-group:not(:last-child) {
  margin-bottom: 16px;
}

.ui-checkbox-group > .checkbox {
  flex-shrink: 0;
  margin-right: 8px;
  height: 16px;
  width: 16px;
  font-size: var(--font-size);
  border: 2px solid var(--color-bw-8);
  border-radius: var(--border-radius);
}

.ui-checkbox-group > .radio {
  flex-shrink: 0;
  margin-right: 8px;
  height: 16px;
  width: 16px;
  font-size: var(--font-size);
  border: 2px solid var(--color-bw-8);
  border-radius: 50%;
}

.ui-checkbox-group > .label {
  display: block;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 8px;
  color: var(--color-bw-7);
  background: var;
  border: 0;
  border-bottom: 1px dashed transparent;
  outline: 0;
}

.ui-checkbox-group > .label:focus,
.ui-checkbox-group > .label:hover {
  border-bottom-color: var(--color-bw-4);
}

.ui-input-group {
  position: relative;
  height: 40px;
  background: var(--color-bw-0);
  border: 0;
  border-bottom: 1px dashed var(--color-bw-3);
}

.ui-input-group > .placeholder {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 8px;
  color: var(--color-bw-4);
}

.ui-input-group > .icon {
  position: absolute;
  top: 4px;
  bottom: 4px;
  right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-bw-8);
  border-radius: var(--border-radius);
}